<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('document_storage')" :isNewButton="true"
                    @new-button-click="showForm('create')" @filter-div-status="datatable.filterStatus = $event">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('document_storage')" :isNewButton="true"
                    @new-button-click="showForm('create')" @filter-div-status="datatable.filterStatus = $event">
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear">
                <b-row>
                    <b-col sm="6">
                        <b-form-group :label="$t('document_type')">
                            <parameter-selectbox  v-model="datatable.queryParams.filter.type"
                             code="document_types"></parameter-selectbox>
                        </b-form-group>
                    </b-col>
                    <!-- <b-col sm="6">
                        <b-form-group :label="$t('description')">
                            <b-form-input v-model="datatable.queryParams.filter.description">
                            </b-form-input>
                        </b-form-group>
                    </b-col> -->
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                :total="datatable.total" :queryParams="datatable.queryParams" :lineNumbers="false"
                @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-per-page-change="onPerPageChange"
                v-show="datatable.showTable">
            </datatable>

            <CommonModal ref="filesModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
                <template v-slot:CommonModalTitle>
                    {{ $t('files') }}
                </template>
                <template v-slot:CommonModalContent>
                    <documents :formId="formId" v-model="form" @close="dataFormClose('filesModal')" />
                </template>
            </CommonModal>
            <CommonModal ref="showModal" size="md" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
                <template v-slot:CommonModalTitle>
                    {{ $t(formType) }}
                </template>
                <template v-slot:CommonModalContent>
                    <data-form :formId="formId" :formType="formType" v-model="form" @close="dataFormClose('showModal')" />
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
//Template
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
//Component
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import CommonModal from "@/components/elements/CommonModal";
//Other
import qs from "qs";
//Service
import DocumentStorageService from "@/services/DocumentStorageService";

export default {
    name: "DocumentStoragePage",
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        Datatable,
        CommonModal,
        DataForm: () => import('./DataForm'),
        Documents: () => import('./Documents'),
    },
    metaInfo() {
        return {
            title: this.$t("document_storage"),
        };
    },
    data() {
        return {
            selectCode: null,
            formId: -1,
            formType: "",
            form: {},
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: "",
                        field: "buttons",
                        html: true,
                        sortable: false,
                        tdClass: "p-0 text-center w-40 align-middle",
                        buttons: [
                            {
                                text: this.$t('show'),
                                class: 'ri-search-eye-line',
                                callback: (row) => {
                                    this.showForm('show', row.id);
                                }
                            },
                            {
                                text: this.$t("edit"),
                                class: "ri-edit-box-line",
                                callback: (row) => {
                                    this.showForm('update', row.id);
                                },
                            },
                            {
                                text: this.$t("delete"),
                                class: "ri-delete-bin-2-line",
                                callback: (row) => {
                                    this.showForm('delete', row.id);
                                },
                            },
                            {
                                text: this.$t("show_files"),
                                class: "ri-list-check",
                                callback: (row) => {
                                    this.openDocuments(row.id);
                                },
                            },
                        ],
                    },
                    {
                        label: this.$t("type"),
                        field: "type_text",
                        sortable: true,
                    },
                    {
                        label: this.$t("description"),
                        field: "description",
                        sortable: true,
                        formatFn: (value, row) => {
                            return row.description || "-"
                        }
                    },

                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: "id",
                    page: 1,
                    limit: 20,
                },
            },
        };
    },
    methods: {
        openDocuments(id) {
            this.formId = id
            this.$refs.filesModal.$refs.commonModal.show()
        },
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {};
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == "desc" ? "-" : "";
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams,
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false }),
            };

            return DocumentStorageService.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data;
                    this.datatable.total = response.data.pagination.total;
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        formClear() {
            this.formId = -1
            this.formType = ""
            this.form = {}
        },
        dataFormClose(modalName = "") {
            if (modalName) {
                this.$refs[modalName].$refs.commonModal.hide()
            }
            this.getRows();
        },
        showForm(type, id = null) {
            if (id) {
                this.formId = id
            }
            this.formType = type
            this.$refs.showModal.$refs.commonModal.show()
        },
    },
};
</script>

