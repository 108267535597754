import Api from "@/services/Index";

// PARAMETER
const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/document-storage", config);
};

const get = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/document-storage/" + id);
};

const store = async (data, id = null) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    if (id) {
        return Api.post("/document-storage/" + id, data);
    }
    return Api.post("/document-storage", data);
};

const update = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put("/document-storage/" + id, formData);
};

const remove = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete("/document-storage/" + id);
};

const deleteDocument = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete("/document-storage/delete/" + id);
};

const addMedia = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post("/document-storage/add-media/", data);
};

const documentDownload = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get("/document-storage/download/" + id, {
        responseType: "arraybuffer",
    });
};

export default {
    getAll,
    get,
    store,
    update,
    remove,
    deleteDocument,
    documentDownload,
    addMedia,
};
